<template>
    <layout>
        <div class="list container mt20">
            <div v-if="subjectList.length" class="container filter-wrapper">
                <filter-tab title="学科" :list="subjectList" :isShowMore="true" type="special" :selected="subjectIndex" @clickItem="handleFilterEvent('subjectIndex', $event)"></filter-tab>
            </div>
            <div class="special-list flex x-between mt30">
                <div class="topic-left t-c">
                <a-spin :spinning="loading" tip="Loading...">
                        <course-item width="230px" height="130px" :data-list="topicList" :targetType="1"
                                    @clickEvent="handleSpecialDetail($event)"></course-item>
                        <a-empty class="t-c" v-if="!topicList.length && !loading" />
                        <div class="t-c mt30 special-pagination" v-if="listCount > 0">
                            <a-pagination :total="listCount" v-model:current="params.page" :page-size="params.limit" show-quick-jumper @change="handlePageChangeEvent"  :hideOnSinglePage="true"/>
                        </div>
                    </a-spin>
                </div>
                <div style="width: 398px;float: right;" class="ml30 topic-right">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
            
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index.vue';
import layoutRight from '@/components/layout/layout-right';
import filterTab from '@/components/filter-tab';
import courseItem from '@/components/courser-list-item';
import { jsonFormat } from '@/utils/jsonFormat.js';
import storage from 'store';
import { mapState } from 'vuex';

let layoutRightConfig = {
    news: {
        show: true, // 是否显示
        methods: 'post',
        title: '会议动态', // 标题
        moreLink:'/infomation-list?index=115',
        data: {
            page: 1,
            limit: 5,
            type: 115,
        },   // 参数条件
    },
    correlationMeet: { //相关会议
        show: true, // 是否显示
        title: '推荐会议', // 标题
        methods: 'get',
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 3,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    },
    correlationLive: { //相关直播
        show: true, // 是否显示
        methods: 'get',
        title: '推荐直播', // 标题
        data: {
            tj_token: storage.get('tid'),
            page: 1,
            limit: 3,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    },
};
export default {
    name: "SpecialMaList",
    components: {
        layout,
        layoutRight,
        filterTab,
        courseItem,
    },
    data() {
        return {
            // 学科筛选
            subjectIndex: -1,
            subjectList: [],
            topicList:[],

            layoutRightConfig:{},
  
            loading: true,
            subject_id:'',
            params:{
                page:1,
                limit:12,
                subject_id:''
            },
            listCount:0,
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        // 学科列表
        getSubject() { // url筛选学科
            let { subject_id } = this.$route.query;
            // 用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;let subject_ids = this.$route.query.subject_id;
            this.$store.dispatch('getSubjectList').then(res=>{
                let subjectList = [];
                res.data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })
                this.subjectList = subjectList;

                // 触发学科修改事件
                this.handleFilterEvent()
            })
        },
        // 专题列表
        getTopicList(){
            this.loading = true
            this.request.post('TopicList',this.params).then(res=>{
                res.data.list.forEach(item=>{
                    if(item.banner == ''){
                        item.banner = 'https://files.sciconf.cn/medcon/20180719/a.jpg'
                    }
                })
                let obj = {
                    img:'banner'
                }
                this.listCount = Number(res.data.count)
                this.topicList = jsonFormat(res.data.list, obj)
                
                this.loading = false
            })
        },
        
        // 学科切换事件
        handleFilterEvent(key, e) {
            key && (this[key] = e);
            this.params.page = 1;

            // 用户url学科或者已选学科
            let subject_ids = this.$route.query.subject_id;
            let operate_subject = subject_ids;

            // 切换后的学科
            if(this.subjectIndex != -1){
                subject_ids = this.subjectList[this.subjectIndex].subject_ids;
                operate_subject = this.subjectList[this.subjectIndex].id;
                this.params.subject_id = operate_subject
            }else{
                delete this.params.subject_id;
            }

            this.getTopicList()

            // 右侧数据修改
            layoutRightConfig.news.data.operate_id = operate_subject;
            layoutRightConfig.correlationMeet.data.subject_ids = subject_ids;
            layoutRightConfig.correlationLive.data.subject_ids = subject_ids;
            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));
            
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getTopicList()
        },
        // 专题详情
        handleSpecialDetail(e){
            let routeData = this.$router.resolve({ 
                path: '/special-ma/index/' + e.id
            });
            window.open(routeData.href, '_blank');
        },
    },
    created() {
        this.getSubject();
    }
}
</script>

<style lang="less" scoped>
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}

.filter-wrapper {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}
.special-list{
    .topic-left{
        width: 770px;
        /deep/.component-box .course-block .course-item .course-info{
            height: 70px;
            .title{
                font-size: 16px;
            }
        }
        /deep/.component-box .course-block .course-item .publicity-picture .bg-img-top{
            width: 100%;
            object-fit: contain;
        }
    }
}


@media screen and (max-width:768px){
    .wrapper{
        .filter-wrapper{
            padding: 2px 0;
            border-radius: 0;
            position: fixed;
            top: 50px;
            background: #f2f4fa;
            z-index: 999999;
        }
    }
    .list{
        margin-top:50px;
    }
    .special-list{
        display: block;
        padding: 0 10px;
        box-sizing: border-box;
        margin-top: 0;
        .topic-right,.topic-left{
            width: 100%!important;
        }
        .topic-right{
            margin-top: 30px;
        }
    }
}
</style>