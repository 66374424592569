<template>
    <div class="layout flex flex-column" ref="iframeContent">
        <!-- <div class="container mt10 mb10 t-l fs18">专题名称：<span class="strong">{{ specialInfo.title }}</span></div>
        <div class="container flex x-left mt10 mb10 t-l fs18">
            <div>学科：<span class="strong">{{ specialInfo.subject_ids }}</span></div>
            <div class="ml20">关键字：<span class="strong">{{ specialInfo.keywords }}</span></div>
        </div> -->
        <div class="nav-ma">
            <div class="banner-ma">
                <img v-if="specialInfo?.banner" :src="specialInfo.banner" alt="">
                <div class="unconfig banner-ma-unconfig fs16" v-else>banner图</div>
                <a-button class="banner-ma-edit" type="primary" size="small" @click="handleBannerEdit">编辑</a-button>
            </div>
            <div class="container-list mt10" :class="{ 'unconfig': !navList.length }">
                <div class="container flex y-center pl10">
                    <div v-if="navList.length" class="flex x-left y-center nav-list">
                        <div v-for="(item, index) in navList" :key="index" class="nav-item">
                            <router-link v-if="item.link_type_id != 99" class="nav-item-text" to="">{{ item.title
                            }}</router-link>
                        </div>
                    </div>
                    <div v-else class="tab-unconfig fs16">导航管理</div>
                    <a-button class="tab-edit" type="primary" size="small" @click="handleNavEdit">编辑</a-button>
                </div>
            </div>
        </div>
        <div class="index-ma-wrapper mt20">
            <div class="container index-container flex">
                <!-- <a-spin :spinning="loading" tip="Loading..." class="home-left"> -->
                <div class="home-left">
                    <draggable v-model="topicSort.leftList" chosenClass="chosen" forceFallback="true" :group="group"
                        animation="300" :sort="true" @start="handleDragStart('dragLeft')" @end="handleDragEnd('dragLeft')">
                        <transition-group>
                            <div v-for="(element, index) in topicSort.leftList" :key="Number(element.id)">
                                <!--  link_type_id 1;//资讯  2;//视频  3;//指南 4;//期刊 5;//会议 6;//专家 7;//机构 8论文 -->
                                <!-- 资讯 -->
                                <div v-if="element.link_type_id == 1" class="left-box mb20">
                                    <div v-if="zixunList.length" class="hascontent-box">
                                        <common-title :isMore="false" :title="element.title" type="ma"></common-title>
                                        <div v-for="(item, zixun_index) in zixunList" :key="zixun_index"
                                            class="hot-topic-box" :class="{ 'mt20': index != 0 }">
                                            <hot-topic-item containerWidth="760px" path="/special-ma/infomation-detail"
                                                :type="item.class_id" :item="item" class="bb"></hot-topic-item>
                                        </div>
                                    </div>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '1', element, index)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','left',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'left', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                                <!-- 视频 -->
                                <div v-if="element.link_type_id == 2" class="left-box mb20">
                                    <div class="hascontent-box" v-if="videoList.length">
                                        <common-title path="/special-ma/video-list" :isMore="false" :title="element.title"
                                            type="ma"></common-title>
                                        <course-item type="ma" lineCount="4" width="178px" height="130px"
                                            :data-list="videoList"></course-item>
                                    </div>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '1', element, index)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','left',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'left', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                                <!-- 指南 -->
                                <div v-if="element.link_type_id == 3" class="left-box mb20">
                                    <div v-if="guideList.length" class="hascontent-box">
                                        <common-title path="/special-ma/guide-list" :isMore="false" :title="element.title"
                                            type="ma"></common-title>
                                        <div class="list-items">
                                            <text-item path="/special-ma/guide-detail" type="2" gap="20"
                                                v-for="(item, guide_index) in guideList" :key="guide_index"
                                                :item="item"></text-item>
                                        </div>
                                    </div>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '1', element, index)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','left',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'left', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                                <!-- 论文 -->
                                <div v-if="element.link_type_id == 8" class="left-box mb20">
                                    <div v-if="paperList.length" class="hascontent-box">
                                        <common-title path="/special-ma/article-list" :isMore="false" :title="element.title"
                                            type="ma"></common-title>
                                        <div class="">
                                            <div v-for="(item, paper_index) in paperList" :key="paper_index"
                                                class="paper-list pb20 t-l" :class="{ 'mt20': index != 0 }"
                                                @click="goArticleDetail(item.id)">
                                                <p class="fs16 color333 strong mb15" style="line-height:1.3;">{{ item.title
                                                    || item.title_en }}</p>
                                                <div class="flex x-left color333 fs12 row-1 mb5"
                                                    v-if="item.real_name || item.org_cnname">
                                                    作者：
                                                    <span v-if="item.real_name" class="mr10"><span class="color333">{{
                                                        item.author_real_name }}</span></span>
                                                    <span v-if="item.org_cnname" class="color999">{{ item.org_cnname
                                                    }}</span>
                                                </div>
                                                <p v-if="item.keywords" class="fs12 color333 mb10">关键词：{{ item.keywords }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '1', element, index)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','left',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'left', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                                <!-- 机构 -->
                                <div v-if="element.link_type_id == 7" class="left-box mb20">
                                    <div v-if="committeeList.length" class="hascontent-box">
                                        <common-title path="/special-ma/org-list/" :isMore="false" :title="element.title"
                                            type="ma"></common-title>
                                        <div class="flex x-left swiper-content">
                                            <committee-item width="177px" v-for="(item, com_index) in committeeList"
                                                :key="com_index" :item="item"></committee-item>
                                        </div>
                                    </div>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '1', element, index)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','left',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'left', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                                <!-- 广告位图片 -->
                                <div v-if="element.link_type_id == 99" class="left-box mb20">
                                    <a-carousel v-if="advertLeftList.length" class="carousel hascontent-box" arrows autoplay :autoplay-speed="4000" style="height:300px;background: #eee;overflow: hidden;">
                                        <!--autoplay-->
                                        <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow"
                                            style="left: 10px;zIndex: 1">
                                            <a-icon type="left-circle" />
                                        </div>
                                        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow"
                                            style="right: 10px">
                                            <a-icon type="right-circle" />
                                        </div>
                                        <img :src="item.image_url | urlFilter(750)" alt="" v-for="(item, index) in advertLeftList"
                                            @touchstart="handleBannerLink(item)" @click="handleBannerLink(item)"
                                            :key="index">
                                    </a-carousel>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '1', element, index)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','left',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'left', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
                <!-- </a-spin> -->
                <div class="home-right t-l">
                    <draggable v-model="topicSort.rightList" chosenClass="chosen" forceFallback="true" :group="group"
                        animation="300" :sort="true" @start="handleDragStart('dragRight')"
                        @end="handleDragEnd('dragRight')">
                        <transition-group>
                            <div v-for="(element, indexs) in topicSort.rightList" :key="element.id">
                                <!--  link_type_id 1;//资讯  2;//视频  3;//指南 4;//期刊 5;//会议 6;//专家 7;//机构 -->
                                <!-- 专家 -->
                                <div v-if="element.link_type_id == 6" class="right-box mb20">
                                    <div v-if="expertList.length" class="hascontent-box">
                                        <common-title path="/special-ma/expert-list" :isMore="false" :title="element.title"
                                            type="ma"></common-title>
                                        <template v-for="(item, ex_index) in expertList">
                                            <recommend-expert-item :item="item" :key="ex_index"></recommend-expert-item>
                                        </template>
                                    </div>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '2', element, indexs)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','right',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'right', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                                <!-- 会议 -->
                                <div v-if="element.link_type_id == 5" class="right-box mb20">
                                    <div v-if="meetingList.length" class="hascontent-box">
                                        <common-title class="mb30" :isMore="false" path='/meeting-list'
                                            :title="element.title" type="ma"></common-title>
                                        <div v-for="(item, meet_index) in meetingList" :key="meet_index"
                                            @click="meetJumpDetail(item.href, item.id)">
                                            <across-media-item :item="item" type="layoutRight">

                                                <template v-slot:bottom>
                                                    <div>
                                                        <div class="row-1" v-if="item.report_time">
                                                            <i class="med med-31shijian"></i>
                                                            <span class="color999 fs14 ml5">{{ item.start_time.slice(0, 10)
                                                            }}</span>
                                                        </div>
                                                        <div class="row-1" v-if="item.address">
                                                            <i class="med med--didian"></i>
                                                            <span class="color999 fs14 ml5">{{ item.address }}</span>
                                                        </div>
                                                    </div>
                                                </template>
                                            </across-media-item>
                                        </div>
                                    </div>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '2', element, indexs)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','right',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'right', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                                <!-- 期刊 -->
                                <div v-if="element.link_type_id == 4" class="right-box mb20">
                                    <div v-if="periodicalRankList.length" class="hascontent-box">
                                        <common-title class="mb30" :isMore="false" :title="element.title"
                                            path="/periodical-list" type="ma"></common-title>
                                        <div class="mt10">
                                            <div class="flex flex-wrap" v-for="(item, per_index) in periodicalRankList"
                                                :key="per_index">
                                                <journal-item width="130px" height="163px" path="/periodical-detail"
                                                    fontSize="16px" :journalItem="item"></journal-item>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '2', element, indexs)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','right',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'right', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                                <!-- 广告位图片 -->
                                <div v-if="element.link_type_id == 99" class="right-box mb20">
                                    <a-carousel v-if="advertRightList.length" class="carousel hascontent-box" arrows autoplay :autoplay-speed="4000" style="height:200px;background: #eee;overflow: hidden;">
                                        <!--autoplay-->
                                        <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow"
                                            style="left: 10px;zIndex: 1">
                                            <a-icon type="left-circle" />
                                        </div>
                                        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow"
                                            style="right: 10px">
                                            <a-icon type="right-circle" />
                                        </div>
                                        <img :src="item.image_url" alt="" v-for="(item, ad_index) in advertRightList"
                                            @touchstart="handleBannerLink(item)" @click="handleBannerLink(item)"
                                            :key="ad_index">
                                    </a-carousel>
                                    <div class="unconfig zixun-unconfig fs16" v-else>{{ element.title }}</div>
                                    <div class="handler"></div>
                                    <div class="content-edit flex x-right" @click.stop="">
                                        <a-switch class="mr10" checked-children="显示" un-checked-children="隐藏"
                                            :checked="element.is_show == '1'"
                                            @change="showChange($event, '2', element, indexs)" />
                                        <!-- <a-button class="edit-title mr10" type="primary" size="small" @click="handleEditModuleTitle('editTitle','left',element)">修改模块名称</a-button> -->
                                        <a-button class="edit-content" type="primary" size="small"
                                            @click="handleEditModuleContent('editContent', 'right', element)">编辑模块数据</a-button>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import hotTopicItem from '@/components/hot-topic-item.vue';
import courseItem from '@/components/courser-list-item';
import textItem from '@/components/text-item.vue';
import committeeItem from '@/components/committee-item';
import recommendExpertItem from '@/components/recommend-expert-item';
import acrossMediaItem from '@/components/across-media-item';
import journalItem from '@/components/journal-item';
import { jsonFormat } from '@/utils/jsonFormat.js';
import { committee_id } from '@/config';

export default {
    name: 'SpecialMaModelConfig',
    components: {
        draggable,
        layout,
        commonTitle,
        layoutRight,
        hotTopicItem,
        courseItem,
        textItem,
        committeeItem,
        recommendExpertItem,
        acrossMediaItem,
        journalItem,
    },
    data() {
        return {
            drag: false,
            specialInfo: null,
            url: '',
            group: {
                name: "sort1",
                pull: false,  //可以拖出
                put: false,   //可以拖入
            },
            topicSort: {},
            //isAdmin:true,
            loading: false,
            imgUrl: "",
            path: this.$route.fullPath,
            navList: [], //导航列表
            zixunList: [], //热点资讯列表
            videoList: [], //相关视频列表
            guideList: [], //相关指南列表
            paperList: [], //相关论文列表
            committeeList: [], //相关机构
            expertList: [], //推荐大咖
            meetingList: [], //相关会议
            periodicalRankList: [], //推荐期刊
            advertLeftList:[], //左侧轮播图
            advertRightList:[], //右侧轮播图
            params: {
                limit: 3, 
                topic_id: this.$route.params.id, 
                page: 1,
                topic_committee_id:committee_id
            }
        }
    },
    computed: {

    },
    methods: {
        handleBannerLink(item){
            item.skip_url &&  window.open(item.skip_url,'_blank')
        },
        // 开始拖拽
        handleDragStart(type) {
            this.drag = true;
        },
        // 结束拖拽
        handleDragEnd(type) {
            let module_data = []
            if (type == 'dragLeft') {
                this.topicSort.leftList.forEach((item, index) => {
                    item.pre_sort_num = index + 1
                    let data = {
                        id: item.id,
                        type: 2,
                        pre_sort_num: item.pre_sort_num
                    }
                    module_data.push(data)
                })
            }
            if (type == 'dragRight') {
                this.topicSort.rightList.forEach((item, index) => {
                    item.pre_sort_num = index + 1
                    let data = {
                        id: item.id,
                        type: 3,
                        pre_sort_num: item.pre_sort_num
                    }
                    module_data.push(data)
                })
            }
            this.request.post('TopicDressSort', { topic_id: this.$route.params.id, committee_id, module_data }).then(res => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.pageInit()
                }
            })
            this.drag = false;
        },
        // 编辑banner图
        handleBannerEdit() {
            // console.log('编辑banner图')
            this.postMessage({ type: 'banner' })
        },
        // 编辑导航栏
        handleNavEdit() {
            this.postMessage({ type: 'nav' })
        },
        // 模块显示隐藏
        showChange(e, type, element, index) {
            // console.log(window)
            console.log(e, type, element, index)
            let data = []
            // window.parent.postMessage({e,type,element}, 'http://127.0.0.1:5555/333.html')
            if (type == '1') {
                // leftList[element.id - 1].is_show = e ? '1' : '0'
                data = [{
                    id: element.id,
                    type: 2,
                    pre_sort_num: element.pre_sort_num,
                    is_show: e ? '1' : '0'
                }]
            } else if (type == '2') {
                // rightList[element.id - 1].is_show = e ? '1' : '0'
                data = [{
                    id: element.id,
                    type: 3,
                    pre_sort_num: element.pre_sort_num,
                    is_show: e ? '1' : '0'
                }]
            }
            this.request.post('TopicDressSort', { topic_id: this.$route.params.id, committee_id, module_data: data }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    // this.$message.success('修改成功');
                    this.pageInit()
                }
            })

        },
        // 修改模块名称
        handleEditModuleTitle(type, direction, element) {
            // console.log('修改模块标题',type,direction,element.title)
            this.postMessage({ type, direction, element })
        },
        // 修改模块数据
        handleEditModuleContent(type, direction, element) {
            // console.log('修改模块数据',type,direction,element.title)
            this.postMessage({ type, direction, element })
        },
        // 热点资讯列表
        getZixunList() {
            this.request.post('GetNews',this.params).then(res => {
                this.zixunList = res.data.list
            })
        },
        // 视频列表
        getVideoList() {
            this.request.get('GetVideoListNew',{
                page: 1,
                PageSize: 4,
                no_count: false,
                topic_id: this.$route.params.id, 
                topic_committee_id: committee_id
            }).then(res=>{
                let videoObj = {
                    img: 'default_pic_url',
                    name: 'author',
                    org: 'org_cnname',
                }
                this.videoList = jsonFormat(res.data.list, videoObj)
            })
        },
        // 指南列表
        getGuideList() {
            this.request.post('GuideList',this.params).then(res => {
                let obj = {
                    id: 'guide_id',
                    news_title: 'title_cn',
                    create_time: 'publish_date',
                    source: 'reference',
                    
                }
                let guideList = jsonFormat(res.data.list, obj)
                guideList.forEach(item => {
                    item.class_type = 0
                    item.typeName = '指南'
                })
                this.guideList = guideList
            })
        },
        // 机构列表
        getCommitteeList() {
            this.request.post('TopicOrgList', { limit: 4, topic_id: this.$route.params.id, page: 1, committee_id }).then(res => {
                let committeeObj = {
                    name: 'title',
                    index_logo: 'img'
                }
                this.committeeList = jsonFormat(res.data.list, committeeObj)
            })
        },
        // 论文列表
        getArticleList() {
            this.request.post('GetMeetingPaper',this.params).then(res => {
                this.paperList = res.data.list
            })
        },
        // 专家列表
        getExpertList() {
            this.request.post('ExpertsListV3', this.params).then(res => {
                this.expertList = res.data.list
            })
        },
        // 广告图列表
        getImgList(lb_id,type){
            this.request.post('TopicImgList', { topic_id: this.$route.params.id, lb_id, committee_id }).then(res => {
                if(type == 'left'){
                    this.advertLeftList = res.data
                }else{
                    this.advertRightList = res.data
                }
            })
        },
        // 会议列表
        getMeetingList() {
            this.request.get('GetMeetingList', {
                page: 1,
                pageSize: 6,
                topic_id: this.$route.params.id, 
                topic_committee_id: committee_id
            }).then(res => {
                let obj = {
                    title: 'cn_title',
                    img: 'pic_url'
                }
                this.meetingList = jsonFormat(res.data.data.slice(-3), obj)
            })
        },
        // 期刊列表
        getPeriodicalList() {
            this.request.post('PeriodicalList', this.params).then(res => {
                let periObj = {
                    desc: 'en_name',
                }
                this.periodicalRankList = jsonFormat(res.data.list, periObj)
            })
        },

        postMessage(value) {
            let url = this.url + 'committee/topic/dress/' + committee_id + '?s_id=' + this.$route.params.id
            window.parent.postMessage(value, url)
        },
        // 接收iframe页面传递数据
        getMessage(e) {
            if (e.data == 'refresh') {
                // this.$message.success('修改成功');
                this.pageInit()
            }
        },
        sortNum(ary, key) {
            return ary.sort(function (a, b) {
                let x = a[key]
                let y = b[key]
                return ((x < y) ? -1 : (x > y) ? 1 : 0)
            })
        },
        pageInit() {
            let reqArr = [
                // 专题详情
                this.request.post('TopicDetail', { topic_id: this.$route.params.id }),
                // 视频
                this.request.post('TopicNavList', { limit: '20', topic_id: this.$route.params.id, page: 1, committee_id }),

                // 获取当前首页排序
                this.request.post('TopicNavSort', { topic_id: this.$route.params.id, committee_id }),
            ]
            Promise.all(reqArr).then(res => {

                if (res[0].data) {
                    res[0].data.other = JSON.parse(res[0].data.other)
                    this.specialInfo = res[0].data
                }

                let navList = res[1].data.list
                // navList.unshift({
                //     title:'首页',
                //     is_show: "1",
                // })
                // this.navList = this.sortNum(navList,'pre_sort_num')
                this.navList = navList

                let topicSort = res[2].data
                topicSort['2'] && (topicSort['leftList'] = this.sortNum(topicSort['2'], 'pre_sort_num'))
                topicSort['3'] && (topicSort['rightList'] = this.sortNum(topicSort['3'], 'pre_sort_num'))

                if (topicSort && topicSort['2']?.length) {
                    topicSort['leftList'].forEach((item, index) => {
                        // link_type_id 1;//资讯  2;//视频  3;//指南 4;//期刊 5;//会议 6;//专家 7;//机构 8论文
                        if (item.link_type_id == 1) {
                            this.getZixunList()
                        } else if (item.link_type_id == 2) {
                            this.getVideoList()
                        } else if (item.link_type_id == 3) {
                            this.getGuideList()
                        } else if (item.link_type_id == 8) {
                            this.getArticleList()
                        } else if (item.link_type_id == 7) {
                            this.getCommitteeList()
                        } else if (item.link_type_id == 99) {
                            this.getImgList(item.id,'left')
                        }
                    })
                }
                if (topicSort && topicSort['3']?.length) {
                    topicSort['rightList'].forEach((item, index) => {
                        // link_type_id 1;//资讯  2;//视频  3;//指南 4;//期刊 5;//会议 6;//专家 7;//机构 8论文
                        if (item.link_type_id == 6) {
                            this.getExpertList()
                        } else if (item.link_type_id == 5) {
                            this.getMeetingList()
                        } else if (item.link_type_id == 4) {
                            this.getPeriodicalList()
                        }else if (item.link_type_id == 99) {
                            this.getImgList(item.id,'right')
                        }
                    })
                }
                this.topicSort = topicSort
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.postMessage({ type: 'setHeight', height: this.$refs.iframeContent.scrollHeight })
                    }, 500)
                })
            }).catch((err) => { console.log(err) })

        },
    },
    created() {

    },
    mounted() {
        this.pageInit()
        this.url = window.document.referrer
        window.addEventListener('message', this.getMessage, false)
    },


    // 缓存页面二次进入的钩子函数
    activated() {

    },
}
</script>
<style lang="less" scoped>
.nav-ma {

    .container-list {
        background: @theme;
        position: relative;

        .tab-unconfig {
            width: 100%;
            height: 50px;
            line-height: 50px;
        }

        .tab-edit {
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .unconfig {
        background: #eee;
    }

    .container {
        .nav-list {
            height: 50px;
        }

    }

    .banner-ma {
        width: 100%;
        height: 100px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-unconfig {
            height: 100%;
            line-height: 100px;
        }

        &-edit {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    .mobile-nav-list {
        display: none;
    }

    .nav-item {
        display: block;
        font-size: .48rem;
        height: 0.93333rem;
        line-height: .93333rem;
        text-align: justify;
        color: #fff;
        margin-left: 30px;

        &:nth-child(1) {
            margin-left: 0;
        }

        &-text {
            display: block;
            font-size: 18px;
            height: 35px;
            line-height: 35px;
            text-align: justify;
            color: #FFFFFF;
            width: 100%;

            &:after {
                content: '';
                width: 100%;
                display: inline-block;
                overflow: hidden;
                height: 0;
            }
        }
    }
}

.index-ma-wrapper {
    overflow: auto;

    .index-container {
        // max-width: 1160px;
    }

    .home-left {
        width: calc(66.66% - 10px);


        .paper-list {
            border-bottom: 1px dashed #d8d8d8;

            &:last-child {
                border: 0;
            }
        }

        .left-box {
            position: relative;

            .hascontent-box:after {
                content: ' ';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.1);
            }

            .handler {
                display: none;
            }

            &:hover {
                .handler {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: #000;
                    opacity: 0.5;
                    border: 2px dashed #fff;
                    cursor: move;
                }
            }
            /deep/.ant-carousel .slick-slide img{
                width: 100%;
                height: 300px;
                display: block;
                object-fit: cover;
            }
        }

        .zixun-unconfig {
            height: 200px;
            line-height: 200px;
            background: #eee;
        }

        .content-edit {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
        }
    }

    .home-right {
        width: 33.33%;

        .right-box {
            position: relative;

            .handler {
                display: none;
            }

            &:hover {
                .handler {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: #000;
                    opacity: 0.5;
                    border: 2px dashed #fff;
                    cursor: move;
                }
            }
        }

        .hascontent-box:after {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.1);
        }

        .zixun-unconfig {
            text-align: center;
            height: 300px;
            line-height: 300px;
            background: #eee;
        }

        .content-edit {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
        }
        .carousel{
            img{
                width: 100%;
                height: 200px;
                min-height: 100px;
                display: block;
                object-fit: cover;
            }
            .slick-dots-bottom{
                bottom: 10px;
            }
        }
    }
}</style>
